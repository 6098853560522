<template>
    <a href="#" @click.prevent="onClick(id)" class="flix-btn flix-btn-xs" :class="{'flix-btn-primary' : active, 'flix-btn-default': !active}">
      <flixIcon :id="'chevron-'+id" />
    </a>
</template>
<script>
export default {
  components: {},
  props: {
    id: String,
    onClick: Function,
    active: Boolean
  },
  data () {
    return {

    }
  },
  methods: {

  },
  mounted () {

  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
